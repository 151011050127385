<template>
  <div>
    <div class="box">
      <div class="table-head-component">征稿</div>
      <p>CCF: Conference Rank (A, B, C) from China Computer Federation (2022)</p>
      <p>CORE: Conference Rank (A*, A, B, C) from Computing Research and Education Association of Australasia
        (2020)</p>
      <p>QUALIS: Conference Rank (A1, A2, B1, B2, B3, B4, B5) from Brazilian Classification System for Conferences and
        Journals (2016)</p>
      <el-table
          :data="tableData0"
          border
          style="width: 100%">

        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="core"
            label="CORE"
            width="60">
        </el-table-column>
        <el-table-column
            prop="qualis"
            label="QUALIS"
            width="70">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
          <!--          <template slot-scope="scope">-->
          <!--            <a @click="viewDetail(scope.row)">{{ scope.row.fullname }}</a>-->
          <!--          </template>-->
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="noticeDate"
            label="通知日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="meetingDate"
            label="会议日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="place"
            label="会议地点"
            width="100">
        </el-table-column>
        <el-table-column
            prop="sessionNumber"
            label="届数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewConferenceDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync=pageNum
          :page-size=pageSize
          layout="total, prev, pager, next"
          :total=total>
      </el-pagination>
    </div>

    <h1></h1>
    <h1></h1>
    <div class="box">
      <div class="table-head-component">正在征稿的期刊</div>
      <p>CCF: Journal Rank (A, B, C) from China Computer Federation (2022)</p>
      <el-table
          :data="tableJournalData"
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="specialIssue"
            label="Special Issue"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="100">
        </el-table-column>

        <el-table-column
            prop="impactFactors"
            label="影响因子"
            width="100">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="出版商"
            width="100">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewJournalDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync=pageNum
          :page-size=pageSize
          layout="total, prev, pager, next"
          :total=total>
      </el-pagination>
    </div>


  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData0:  [],
      tableJournalData: [],
      pageNum: 1,
      pageSize: 10,
      total:121
    }
  },
  methods: {
    handleSizeChange(){

    },
    handleCurrentChange(){

    }
  },

  mounted() {
    this.tableData0 = this.$route.params.tableData0.slice(0, 10);
    this.tableJournalData = this.$route.params.tableJournalData.slice(0, 10);
  }
}
</script>

<style scoped>

</style>