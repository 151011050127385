import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userData: {
            userId: null,
            firstname: '',
            lastname: '',
            fullname: '',
            email: '',
            institution: '',
            createTime: '',
            vitality: 0,
            resume: null,
            role:1,
            followConferences: [],
            attendConferences: [],
            browseConferences: [],
            followJournals: [],
            browseJournals: [],
            followUsers: [],
            followWorks: null
        }
    },
    mutations: {
        setUserData(state, userData) {
            state.userData = userData;
        },
        clearUserData(state) {
            state.userData.userId = null;
            state.userData.firstname = '';
            state.userData.lastname = '';
            state.userData.fullname = '';
            state.userData.email = '';
            state.userData.institution = '';
            state.userData.createTime = '';
            state.userData.vitality = 0;
            state.userData.resume = null;
            state.userData.followConferences = [];
            state.userData.attendConferences = [];
            state.userData.browseConferences = [];
            state.userData.followJournals = [];
            state.userData.browseJournals = [];
            state.userData.followUsers = [];
            state.userData.followWorks = null;
        }
    },
    actions: {},
    modules: {}
});
