<template>
  <div>
    <el-card class="box-card">
      <div class="conference-details">
        <h2>{{ conference.fullname }}</h2>
        <p><strong>会议缩写:</strong> {{ conference.shortname }}</p>
        <p><strong>会议网址:</strong> <a :href="conference.website" target="_blank">{{ conference.website }}</a></p>
        <p><strong>截稿日期:</strong> {{ conference.deadline }}</p>
        <p><strong>通知日期:</strong> {{ conference.noticeDate }}</p>
        <p><strong>会议日期:</strong> {{ conference.meetingDate }}</p>
        <p><strong>会议地点:</strong> {{ conference.place }}</p>
        <p><strong>届数:</strong> {{ conference.sessionNumber }}
          <strong> 浏览:</strong> {{ conference.browse }}
          <strong> 关注:</strong> {{ conference.follow }}
          <strong> 参加:</strong> {{ conference.attend }}</p>
        <el-button
            @click.native.prevent="followConference()"
            size="small">
          关注
        </el-button>
        <el-button
            @click.native.prevent="attendConference()"
            size="small">
          参会
        </el-button>
      </div>
      <el-divider></el-divider>
      <div>
        <h3>征稿主题</h3>
        <p>Topics of interest include,but are not limited to:</p>
        <p> {{ conference.detail }}</p>
      </div>
      <el-divider></el-divider>
    </el-card>
    <div class="box">
      <div class="table-head-component">相关会议</div>
      <el-table
          :data="tableRelatedConference"
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="core"
            label="CORE"
            width="60">
        </el-table-column>
        <el-table-column
            prop="qualis"
            label="QUALIS"
            width="70">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="noticeDate"
            label="通知日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="meetingDate"
            label="会议日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="place"
            label="会议地点"
            width="100">
        </el-table-column>
        <el-table-column
            prop="sessionNumber"
            label="届数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
      </el-table>
    </div>
    <div class="box">
      <div class="table-head-component">相关期刊</div>
      <el-table
          :data="tableRelatedJournal"
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="specialIssue"
            label="Special Issue"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="100">
        </el-table-column>

        <el-table-column
            prop="impactFactors"
            label="影响因子"
            width="100">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="出版商"
            width="100">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BASE_URL from "@/utils/request";
import swal from "sweetalert";
import {mapState} from "vuex";

export default {
  data() {
    return {
      conference: this.$route.params.conference || {},
      tableRelatedConference: [],
      tableRelatedJournal: [],
    };
  },
  watch: {
    '$route.params.conference': function (newVal) {
      this.conference = newVal;
    }
  },
  computed: {
    ...mapState({
      userData: state => state.userData
    })
  },
  methods:{
    async followConference(){
      console.log(this.userData.userId)
      try {
        const response = await axios.post(BASE_URL+'/interaction/add', null,{
          params:{
            userId: this.userData.userId,
            entityType: "conference",
            entityId: this.conference.conferenceId,
            action: "follow"
          }

        });
        console.log(response.data)
        if (response.data.code==0) {
          // console.log(response.data.data)
          swal({
            title: "关注会议",
            text: "关注"+this.conference.fullname+"会议成功",
            icon: "success",
            button: "好的",
          });
        } else if(response.data.code==1){
          swal({
            title: "关注会议",
            text: "关注"+this.conference.fullname+"，但你已经关注了",
            icon: "error",
            button: "好的",
          });
        } else{
          this.$message.error('关注失败');
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取关注会议error');
      }
    }
  }
};
</script>
<style scoped>
.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}

.conference-details {
  text-align: center; /* 居中对齐所有文本内容 */
}

.conference-details h2 {
  margin-bottom: 20px; /* 可选：增加标题与内容之间的间距 */
}
</style>