<!-- components/journalForm.vue -->
<template>

  <div class="box">
    <div class="table-head-component">
      <i class="el-icon-edit"></i> 新增期刊
    </div>
    <p></p>
    <el-form ref="form" :model="journalData" label-width="100px">
      <el-form-item label="全称">
        <el-input v-model="journalData.fullname"></el-input>
      </el-form-item>

      <el-form-item label="截稿日期">
        <el-date-picker
            v-model="journalData.deadline"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="通知日期">
        <el-date-picker
            v-model="journalData.noticeDate"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-input v-model="journalData.status"></el-input>
      </el-form-item>
      <el-form-item label="出版商">
        <el-input v-model="journalData.publisher"></el-input>
      </el-form-item>

      <el-form-item label="issn">
        <el-input v-model="journalData.issn"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" v-model="journalData.detail"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancelSubmit">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
import BASE_URL from "@/utils/request";

export default {
  data() {
    return {
      journalData: {}
    };
  },
  methods: {
    cancelSubmit() {
      this.$emit('cancel');
      swal({
        title: "新增期刊",
        text: "你取消了新增期刊",
        icon: "error",
        button: "我知道了",
      });
    },
    onSubmit() {
      this.$emit('submit', this.journalData);
      axios.post(BASE_URL+'/journal/add', {
        fullname:this.journalData.fullname,
        deadline:this.journalData.deadline,
        noticeDate:this.journalData.noticeDate,
        status:this.journalData.status,
        publisher:this.journalData.publisher,
        issn:this.journalData.issn,
        place:this.journalData.place,
        detail:this.journalData.detail,
      }).then(res => {
        res.data
        // console.log(res.data.data.records);
        swal({
          title: "新增期刊",
          text: "你成功新增了一条期刊",
          icon: "success",
          button: "我知道了",
        });
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }
};
</script>

<style scoped>
/* 可以添加自定义样式 */
</style>
