<template>
  <div>
    <div class="box">
      <div class="table-head-component">征稿</div>
      <p>CCF: Conference Rank (A, B, C) from China Computer Federation (2022)</p>
      <p>CORE: Conference Rank (A*, A, B, C) from Computing Research and Education Association of Australasia (2020)</p>
      <p>QUALIS: Conference Rank (A1, A2, B1, B2, B3, B4, B5) from Brazilian Classification System for Conferences and
        Journals (2016)</p>
      <el-table
          :data="tableData0"
          stripe
          border
          style="width: 100%">

        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="core"
            label="CORE"
            width="60">
        </el-table-column>
        <el-table-column
            prop="qualis"
            label="QUALIS"
            width="70">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
<!--          <template slot-scope="scope">-->
<!--            <a @click="viewDetail(scope.row)">{{ scope.row.fullname }}</a>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="noticeDate"
            label="通知日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="meetingDate"
            label="会议日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="place"
            label="会议地点"
            width="100">
        </el-table-column>
        <el-table-column
            prop="sessionNumber"
            label="届数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewConferenceDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="followConference(scope.row)"
                size="small">
              关注
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="attendConference(scope.row)"
                size="small">
              参会
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="handletableData0CurrentChange"
          :current-page.sync=tableData0current
          :page-size=tableData0size
          layout="total, prev, pager, next"
          :total=tableData0total>
      </el-pagination>
    </div>
    <h1></h1>
    <h1></h1>
    <div class="box">
      <div class="table-head-component">征稿结束</div>
      <el-table
          :data="tableData1"
          stripe
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="core"
            label="CORE"
            width="60">
        </el-table-column>
        <el-table-column
            prop="qualis"
            label="QUALIS"
            width="70">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="postponed"-->
<!--            label="延期"-->
<!--            width="50">-->
<!--        </el-table-column>-->
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="noticeDate"
            label="通知日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="meetingDate"
            label="会议日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="place"
            label="会议地点"
            width="100">
        </el-table-column>
        <el-table-column
            prop="sessionNumber"
            label="届数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewConferenceDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="followConference(scope.row)"
                size="small">
              关注
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="attendConference(scope.row)"
                size="small">
              参会
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          @current-change="handletableData1CurrentChange"
          :current-page.sync=tableData1current
          :page-size=tableData1size
          layout="total, prev, pager, next"
          :total=tableData1total>
      </el-pagination>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import BASE_URL from '@/utils/request'
import {mapState} from "vuex";
import swal from "sweetalert";

export default {
  data() {
    return {
      tableData0: [{
        ccf: 'a',
        core: 'b',
        qualis: 'a',
        shortname: 'cvpr',
        fullname: '默认',
      }],
      tableData1: [],

      tableData0current: 1,
      tableData0size: 10,
      tableData0total:125,
      tableData1current: 1,
      tableData1size: 10,
      tableData1total:125
    }
  },
  mounted() {
    this.fetchData0();
    this.fetchData1();

  },
  computed: {
    ...mapState({
      userData: state => state.userData
    })
  },
  methods: {
    fetchData0() {
      // 显示数据
      //status: 0代表未截止
      axios.get(BASE_URL+'/conference/list', {
        params: {
          status: 0,
          pageNum: this.tableData0current,
          pageSize: this.tableData0size
        }
      }).then(res => {
        console.log(res.data.data.records);
        this.tableData0 = res.data.data.records
        this.tableData0current = res.data.data.current
        this.tableData0size = res.data.data.size
        this.tableData0total = res.data.data.total
      }).catch(error => {
        console.error('Error fetching data:', error);
      })
    },
    fetchData1(){
      // status: 1代表已截止
      axios.get(BASE_URL+'/conference/list', {
        params: {
          status: 1,
          pageNum: this.tableData1current,
          pageSize: this.tableData1size
        }
      }).then(res => {
        // console.log(res.data.data);
        this.tableData1 = res.data.data.records
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },
    async viewConferenceDetail(row) {
      const conferenceId=row.conferenceId
      try {
        const response = await axios.get(BASE_URL+'/conference/detail', {
          params: {
            conferenceId: conferenceId,
          }
        });
        if (response.data.code==0) {
          // console.log(response.data.data)
          this.$router.push({ name: 'conferenceDetail', params: { conference: response.data.data } });
        } else {
          this.$message.error('获取会议详情失败');
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取会议详情失败');
      }
    },
    async followConference(row){
      const conferenceId=row.conferenceId
      console.log("1"+this.userData.userId)
      try {
        const response = await axios.post(BASE_URL+'/interaction/add', null,{
          params:{
            userId: this.userData.userId,
            entityType: "conference",
            entityId: conferenceId,
            action: "follow"
          }
        });
        console.log(response.data)
        if (response.data.code==0) {
          // console.log(response.data.data)
          swal({
            title: "关注会议",
            text: "关注"+row.fullname+"会议成功",
            icon: "success",
            button: "好的",
          });
        } else if(response.data.code==1){
          swal({
            title: "关注会议",
            text: "关注"+row.fullname+"，但你已经关注了",
            icon: "error",
            button: "好的",
          });
        } else{
          this.$message.error('关注失败');
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取关注会议error');
      }
    },
    async attendConference(row){
      const conferenceId=row.conferenceId
      console.log(conferenceId)
    },
    handletableData0CurrentChange(){
      this.fetchData0()
    },
    handletableData1CurrentChange(){
      this.fetchData1()
    }
  }
}

</script>

<style>

.box {
  border: 2px solid #000099; /* 外框颜色和宽度 */
  padding: 2px; /* 内边距，增加框内的空间 */
  border-radius: 10px; /* 圆角边框 */
  flex-direction: column;
  gap: 100px; /* 添加间距 */
  width: 90%; /* 给盒子一个宽度 */
  max-width: 2000px; /* 最大宽度 */
  background-color: #F5F5F9;
}
.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}
</style>