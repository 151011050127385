<template>
  <div>
    <div class="box">
      <div class="table-head-component">
        <i class="el-icon-edit"></i> 注册新用户
      </div>
      <p></p>
      <el-form ref="form" :model="userData" label-width="100px">
        <el-form-item label="姓">
          <el-input v-model="userData.lastname"></el-input>
        </el-form-item>
        <el-form-item label="名">
          <el-input v-model="userData.firstname"></el-input>
        </el-form-item>
        <el-form-item label="全名">
          <el-input v-model="userData.fullname"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userData.email"></el-input>
        </el-form-item>
        <el-form-item label="机构">
          <el-input v-model="userData.institution"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="newPassword1"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input type="password" v-model="newPassword2"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input type="textarea" v-model="userData.resume"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">注册</el-button>
          <el-button @click="cancelSubmit">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import BASE_URL from '@/utils/request'

export default {
  name: 'RegisterPage',
  data() {
    return {
      userData: {
        lastname: '',
        firstname: '',
        fullname: '',
        email: '',
        institution: '',
        resume: ''
      },
      newPassword1: '',
      newPassword2: ''
    };
  },
  methods: {
    async onSubmit() {
      if (this.newPassword1 !== this.newPassword2) {
        this.$message.error('两次输入的密码不一致');
        return;
      }

      try {
        const response = await axios.post(BASE_URL + '/user/register', {
          ...this.userData,
          password: this.newPassword1
        });

        if (response.data.success) {
          this.$message.success('注册成功');
          this.$router.push('/login');
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        this.$message.error('注册失败');
      }
    },
    cancelSubmit() {
      this.$router.push('/login');
    },
  }
}
</script>

<style scoped>
.box {
  border: 1px solid #dcdfe6; /* 外框颜色和宽度 */
  padding: 10px; /* 内边距，增加框内的空间 */
  border-radius: 4px; /* 圆角边框 */
}

.table-head-component {
  background-color: #99ccff; /* 这里设置了背景颜色 */
  padding: 10px;
  border: 1px solid #ccc;
}
</style>
