<template>
  <div>
    <div class="box">
      <div class="table-head-component">正在征稿的期刊</div>
      <p>CCF: Journal Rank (A, B, C) from China Computer Federation (2022)</p>
      <el-table
          :data="tableJournalData"
          stripe
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="specialIssue"
            label="Special Issue"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="100">
        </el-table-column>

        <el-table-column
            prop="impactFactors"
            label="影响因子"
            width="100">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="出版商"
            width="100">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewJournalDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="followJournal(scope.row)"
                size="small">
              关注
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          @current-change="handleJournalChange"
          :current-page.sync=tableJournalcurrent
          :page-size=tableJournalsize
          layout="total, prev, pager, next"
          :total=tableJournaltotal>
      </el-pagination>
    </div>
    <div class="box">
      <div class="table-head-component">期刊列表</div>
      <p></p>
      <el-table
          :data="tableNameData"
          stripe
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="factor"
            label="影响因子"
            width="100">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="出版商"
            width="100">
        </el-table-column>
        <el-table-column
            prop="issn"
            label="ISSN"
            width="100">
        </el-table-column>
        <el-table-column
            prop="browseNumber"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="followJournal(scope.row)"
                size="small">
              关注
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="handleJournalNameChange"
          :current-page.sync=tableJournalNamecurrent
          :page-size=tableJournalNamesize
          layout="total, prev, pager, next"
          :total=tableJournalNametotal>
      </el-pagination>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import BASE_URL from '@/utils/request'

export default {
  mounted() {
    this.fetchJournalData();
    this.fetchJournalNameData();
  },
  methods: {
    fetchJournalData() {
      // 显示数据
      axios.get(BASE_URL + '/journal/list', {
        params: {
          status: 0,
          pageNum: this.tableJournalcurrent,
          pageSize: this.tableJournalsize
        }
      }).then(res => {
        console.log(res.data.data);
        this.tableJournalData = res.data.data.records;
        this.tableJournalcurrent = res.data.data.current
        this.tableJournalsize = res.data.data.size
        this.tableJournaltotal = res.data.data.total
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },
    fetchJournalNameData() {

    },
    handleJournalChange(){
      this.fetchJournalData();
    },
    handleJournalNameChange(){
      this.fetchJournalData();
    },
    async viewJournalDetail(row) {
      const journalId = row.journalId
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(BASE_URL + '/journal/detail', {
          params: {
            journalId: journalId
          },
          headers: {
            'Authorization': token // 设置请求头部
          }
        });
        if (response.data.code == 0) {
          console.log(response.data.data)
          this.$router.push({name: 'journalDetail', params: {journal: response.data.data}});
        } else {
          console.log(response)
          this.$message.error('获取期刊详情失败1');
        }
      } catch
          (error) {
        console.error(error);
        this.$message.error('获取期刊详情失败2');
      }
    },
  }
  ,
  data() {
    return {
      tableJournalData: [],
      tableNameData: [],
      tableData2: [],
      tableJournalcurrent: 1,
      tableJournalsize: 10,
      tableJournaltotal:125,
      tableJournalNamecurrent: 1,
      tableJournalNamesize: 10,
      tableJournalNametotal:125,
    }
  }
}

</script>
<style>
.box {
  border: 1px solid #dcdfe6; /* 外框颜色和宽度 */
  padding: 10px; /* 内边距，增加框内的空间 */
  border-radius: 4px; /* 圆角边框 */
}

.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}
</style>