import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/user/homePage.vue'
import Conference from '@/views/user/conferencePage.vue'
import Journal from '@/views/user/journalPage.vue'
import Account from '@/views/user/accountPage.vue'
import AccountEdit from '@/views/user/accountEditPage.vue'

import Institution from '@/views/user/institutionPage.vue';
import ConferenceDetail from '@/views/user/conferenceDetail.vue';
import JournalDetail from '@/views/user/journalDetail.vue';
import SearchResult from '@/views/user/searchResultPage.vue';

import Login from '@/views/loginPage.vue';
import Logout from '@/views/loginPage.vue';
import Register from '@/views/registerPage.vue';

import Manage from '@/views/manager/managePage.vue'


Vue.use(Router)// 注册路由插件

export default new Router({
    routes: [
        {
            path: '/',
            name: 'user',
            redirect: '/home',
            component: () => import("../layout/userFront.vue"),
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: Home
                }, {
                    path: '/conference',
                    name: 'conference',
                    component: Conference
                },
                {
                    path: '/journal',
                    name: 'journal',
                    component: Journal
                }, {
                    path: '/account',
                    name: 'account',
                    component: Account
                },{
                    path: '/account/edit',
                    name: 'accountEdit',
                    component: AccountEdit
                }, {
                    path: '/institution',
                    name: 'institution',
                    component: Institution
                }, {
                    path: '/login',
                    name: 'Login',
                    component: Login
                }, {
                    path: '/logout',
                    name: 'logout',
                    component: Logout
                }, {
                    path: '/register',
                    name: 'register',
                    component: Register
                },{
                    path: '/conferenceDetail',
                    name: 'conferenceDetail',
                    component: ConferenceDetail
                }, {
                    path: '/journalDetail',
                    name: 'journalDetail',
                    component: JournalDetail
                },{
                    path: '/manage',
                    name: 'Manage',
                    component: Manage
                },{
                    path: '/searchResult',
                    name: 'SearchResult',
                    component: SearchResult
                }

            ]
        }
    ]
})