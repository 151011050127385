<template>
  <div>
    <h1></h1>
    <h1></h1>
    <!--    首页四个显示-->
    <div>
      <el-row :gutter="20" class="flex-container">
        <el-col :span="10">
          <div><i class="el-icon-files"></i></div>
          <div>
            <el-statistic
                group-separator=","
                :value="conferences"
                title="会议"
            ></el-statistic>
          </div>
        </el-col>

        <el-col :span="10">
          <div><i class="el-icon-notebook-2"></i></div>
          <div>
            <el-statistic
                group-separator=","
                :value="journals"
                title="期刊"
            ></el-statistic>
          </div>
        </el-col>

        <el-col :span="10">
          <div><i class="el-icon-s-custom"></i></div>
          <div>
            <el-statistic
                group-separator=","
                :value="users"
                title="科研人员"
            ></el-statistic>
          </div>
        </el-col>

        <el-col :span="10">
          <div><i class="el-icon-timer"></i></div>
          <div>
            <el-statistic
                group-separator=","
                :value="browses"
                title="页面浏览"
            ></el-statistic>
          </div>
        </el-col>

      </el-row>
    </div>
    <h1></h1>
    <h1></h1>
    <!--最新消息-->
    <div class="box">
      <div class="table-head-component">最新消息</div>
      <el-table
          :data="tableDataNews"
          stripe
          border
          style="width: 100%">
        <el-table-column
            prop="id"
            label="#"
            width="50">
        </el-table-column>
        <el-table-column
            prop="time"
            label="日期"
            width="100">
        </el-table-column>
        <el-table-column
            prop="type"
            label="类型"
            width="100">
        </el-table-column>
        <el-table-column
            prop="content"
            label="内容"
            width="300">
        </el-table-column>
      </el-table>
    </div>
    <!--会议-->
    <div class="box">
      <div class="table-head-component">会议征稿</div>
      <p>CCF: Conference Rank (A, B, C) from China Computer Federation (2022)</p>
      <p>CORE: Conference Rank (A*, A, B, C) from Computing Research and Education Association of Australasia (2020)</p>
      <p>QUALIS: Conference Rank (A1, A2, B1, B2, B3, B4, B5) from Brazilian Classification System for Conferences and
        Journals (2016)</p>
      <el-table
          :data="tableDataConference0"
          stripe
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="core"
            label="CORE"
            width="60">
        </el-table-column>
        <el-table-column
            prop="qualis"
            label="QUALIS"
            width="70">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="noticeDate"
            label="通知日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="meetingDate"
            label="会议日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="place"
            label="会议地点"
            width="100">
        </el-table-column>
        <el-table-column
            prop="sessionNumber"
            label="届数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewConferenceDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="followConference(scope.row)"
                size="small">
              关注
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="button-container">
        <el-button type="success" plain @click="showConferences">更多 >></el-button>
      </div>
    </div>
    <!--    会议-->
    <!--    <div class="box">-->
    <!--      会议名列表-->
    <!--      <p>CCF: Conference Rank (A, B, C) from China Computer Federation (2022)</p>-->
    <!--      <p>CORE: Conference Rank (A*, A, B, C) from Computing Research and Education Association of Australasia (2020)</p>-->
    <!--      <p>QUALIS: Conference Rank (A1, A2, B1, B2, B3, B4, B5) from Brazilian Classification System for Conferences and-->
    <!--        Journals (2016)</p>-->
    <!--      <el-table-->
    <!--          :data="tableDataConferenceList"-->
    <!--          border-->
    <!--          style="width: 100%">-->
    <!--        <el-table-column-->
    <!--            prop="ccf"-->
    <!--            label="CCF"-->
    <!--            width="50">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="core"-->
    <!--            label="CORE"-->
    <!--            width="60">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="qualis"-->
    <!--            label="QUALIS"-->
    <!--            width="70">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="shortname"-->
    <!--            label="简称"-->
    <!--            width="100">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="fullname"-->
    <!--            label="全称"-->
    <!--            width="400">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="meetingDate"-->
    <!--            label="会议日期"-->
    <!--            width="70">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="place"-->
    <!--            label="会议地点"-->
    <!--            width="100">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="sessionNumber"-->
    <!--            label="届数"-->
    <!--            width="50">-->
    <!--        </el-table-column>-->
    <!--        <el-table-column-->
    <!--            prop="browseNumber"-->
    <!--            label="浏览"-->
    <!--            width="70">-->
    <!--        </el-table-column>-->
    <!--      </el-table>-->
    <!--    </div>-->
    <!--    期刊-->
    <div class="box">
      <div class="table-head-component">期刊征稿</div>
      <p>CCF: Journal Rank (A, B, C) from China Computer Federation (2022)</p>
      <el-table
          :data="tableDataJournal"
          stripe
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="specialIssue"
            label="Special Issue"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="100">
        </el-table-column>

        <el-table-column
            prop="impactFactors"
            label="影响因子"
            width="100">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="出版商"
            width="100">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="viewJournalDetail(scope.row)"
                type="text"
                size="small">
              详细信息
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="80">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="followJournal(scope.row)"
                size="small">
              关注
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="button-container">
        <el-button type="success" plain @click="showJournals">更多 >></el-button>
      </div>
    </div>
    <h1></h1>
    <h1></h1>
    <!--    数量排名：会议-->
    <div class="rank">
      <el-col :span="8" class="box">
        <div class="table-head-component">
          <i class="el-icon-files">最多浏览</i>
        </div>

        <el-table
            :data="tableDataConferenceBrowse"
            stripe
            border
            style="width: 100%">
          <el-table-column
              prop="conferenceId"
              label="#"
              width="50">
          </el-table-column>
          <el-table-column
              prop="fullname"
              label="会议"
              width="400">
          </el-table-column>
          <el-table-column
              prop="actionCount"
              label="浏览"
              width="80">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="8" class="box">
        <div class="table-head-component">
          <i class="el-icon-files">最多关注</i>
        </div>
        <el-table
            :data="tableDataConferenceFollow"
            stripe
            border
            style="width: 100%">
          <el-table-column
              prop="conferenceId"
              label="#"
              width="50">
          </el-table-column>
          <el-table-column
              prop="fullname"
              label="会议"
              width="400">
          </el-table-column>
          <el-table-column
              prop="actionCount"
              label="关注"
              width="80">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="8" class="box">
        <div class="table-head-component">
          <i class="el-icon-files">最多参加</i>
        </div>
        <el-table
            :data="tableDataConferenceAttend"
            stripe
            border
            style="width: 100%">
          <el-table-column
              prop="conferenceId"
              label="#"
              width="50">
          </el-table-column>
          <el-table-column
              prop="fullname"
              label="会议"
              width="400">
          </el-table-column>
          <el-table-column
              prop="actionCount"
              label="参加"
              width="80">
          </el-table-column>
        </el-table>
      </el-col>
    </div>
    <h1></h1>
    <!--    数量排名：期刊-->
    <div class="rank">
      <div class="centered-container">
        <el-col :span="10" class="box">
          <div class="table-head-component">
            <i class="el-icon-files">最多浏览</i>
          </div>
          <el-table
              :data="tableDataJournalBrowse"
              stripe
              border
              style="width: 100%">
            <el-table-column
                prop="journalId"
                label="#"
                width="50">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="期刊"
                width="400">
            </el-table-column>
            <el-table-column
                prop="actionCount"
                label="浏览"
                width="80">
            </el-table-column>
          </el-table>
        </el-col>
      </div>
      <div class="centered-container">
        <el-col :span="10" class="box">
          <div class="table-head-component">
            <i class="el-icon-files">最多关注</i>
          </div>
          <el-table
              :data="tableDataJournalFollow"
              stripe
              border
              style="width: 100%">
            <el-table-column
                prop="journalId"
                label="#"
                width="50">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="期刊"
                width="400">
            </el-table-column>
            <el-table-column
                prop="actionCount"
                label="关注"
                width="80">
            </el-table-column>
          </el-table>
        </el-col>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BASE_URL from '@/utils/request.js'

export default {
  mounted() {
    this.fetchTop4Data();
    this.fetchNewsData();
    this.fetchConferenceData();
    this.fetchConferenceNameData();
    this.fetchJournalData();
    // 五个带排名的
    this.fetchEntityAction('conference', 'browse');
    this.fetchEntityAction('conference', 'follow');
    this.fetchEntityAction('conference', 'attend');
    this.fetchEntityAction('journal', 'browse');
    this.fetchEntityAction('journal', 'follow');
  },
  methods: {
    fetchTop4Data() {
      axios.get(BASE_URL + '/info/statistics').then(res => {
        this.conferences = res.data.data[0]
        this.journals = res.data.data[1]
        this.users = res.data.data[2]
        this.browses = res.data.data[3]
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },
    fetchNewsData() {
      axios.get(BASE_URL + '/info/news').then(res => {
        // 没完成
        console.log(res)
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },
    fetchConferenceData() {
      // 显示数据
      //status: 0代表未截止
      // axios.get(BASE_URL+'/conference/list', {
      //   params: {
      //     status: 0,
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize
      //   }
      // }).then(res => {
      //   // console.log("fetchConferenceData");
      //   // console.log(res.data.data);
      //   this.tableDataConference0 = res.data.data.records
      // }).catch(error => {
      //   console.error('Error fetching data:', error);
      // });
      axios.get(BASE_URL + '/conference/cache').then(res => {
        // console.log("fetchConferenceData");
        // console.log(res.data.data);
        this.tableDataConference0 = res.data.data
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },
    fetchConferenceNameData() {

    },

    fetchJournalData() {
      // 显示数据
      axios.get(BASE_URL + '/journal/cache').then(res => {
        // console.log(res.data.data);
        this.tableDataJournal = res.data.data
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },

    fetchEntityAction(entityType, action) {
      let url;
      if (entityType == "conference") {
        url = '/conference/actionCache'
      } else if (entityType == "journal") {
        url = '/journal/actionCache'
      } else {
        // 你可以根据需要添加默认情况的处理，例如抛出一个错误或返回一个默认值
        throw new Error('Unsupported entity type');
      }
      axios.get(BASE_URL + url, {
        params: {
          action: action,
        }
      })
          .then(res => {
            // console.log(`${entityType} ${action} data:`, res.data.data);
            this[`tableData${this.capitalize(entityType)}${this.capitalize(action)}`] = res.data.data;
          })
          .catch(error => {
            console.error(`Error fetching ${entityType} ${action} data:`, error);
          });
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handlePageChange(newPage) {
      this.pageNum = newPage;
      this.fetchData();
    },
    showConferences() {
      console.log('Alert clicked');
      this.$router.replace('/conference');
    },
    showJournals() {
      console.log('Alert clicked');
      this.$router.replace('/journal');
    },
    async viewConferenceDetail(row) {
      const conferenceId = row.conferenceId
      try {
        const response = await axios.get(BASE_URL + '/conference/detail', {
          params: {
            conferenceId: conferenceId,
          }
        });
        if (response.data.code == 0) {
          // console.log(response.data.data)
          this.$router.push({name: 'conferenceDetail', params: {conference: response.data.data}});
        } else {
          this.$message.error('获取会议详情失败');
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取会议详情失败');
      }
    }, async viewJournalDetail(row) {
      const journalId = row.journalId
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(BASE_URL + '/journal/detail', {
          params: {
            journalId: journalId
          },
          headers: {
            'Authorization': token // 设置请求头部
          }
        });
        if (response.data.code == 0) {
          console.log(response.data.data)
          this.$router.push({name: 'journalDetail', params: {journal: response.data.data}});
        } else {
          console.log(response)
          this.$message.error('获取期刊详情失败1');
        }
      } catch
          (error) {
        console.error(error);
        this.$message.error('获取期刊详情失败2');
      }
    },

  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 2,
      conferences: 2813,
      journals: 1035,
      users: 1531,
      browses: 75328,

      tableDataNews: [],

      tableDataConference0: [],
      tableDataConferenceList: [],
      tableDataJournal: [],

      tableDataConferenceBrowse: [],
      tableDataConferenceFollow: [],
      tableDataConferenceAttend: [],
      tableDataJournalBrowse: [],
      tableDataJournalFollow: []

    };
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 80%; /* 设置宽度 */
  height: 100px; /* 设置高度 */
  margin: 0 auto; /* 使容器在页面中居中 */
  padding: 20px; /* 添加内边距 */
}

.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}

.flex-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.flex-container .el-col {
  display: flex;
  align-items: center; /* 确保 el-col 内部内容也垂直居中对齐 */
}

.flex-container .el-col i {
  font-size: 24px; /* 调整图标大小 */
  margin-right: 10px; /* 调整图标和统计文本之间的间距 */
}

.box {
  border: 1px solid #dcdfe6; /* 外框颜色和宽度 */
  padding: 10px; /* 内边距，增加框内的空间 */
  border-radius: 4px; /* 圆角边框 */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh; /* 可根据需要调整高度 */
}

.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}

.centered-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  flex-wrap: wrap; /* 如果需要换行 */
  width: 1000px;
  padding: 20px; /* 添加内边距 */
}

.rank {
  display: flex;
}
</style>