<template>
  <div>

    <div class="conference-details">
      <el-button type="primary" style="padding: 30px 20px; font-size: 18px;" @click="showConferenceForm">新增会议</el-button>
      <el-button type="success" style="padding: 30px 20px; font-size: 18px;" @click="showJournalForm">新增期刊</el-button>
    </div>

    <!-- Modal for Conference Form -->
    <el-dialog
        title="新增会议"
        :visible.sync="showConferenceModal"
        width="50%"
        :before-close="handleCloseConference">
      <conference-form @submit="submitConference" @cancel="cancelConference"></conference-form>
    </el-dialog>

    <!-- Modal for Journal Form -->
    <el-dialog
        title="新增期刊"
        :visible.sync="showJournalModal"
        width="50%"
        :before-close="handleCloseJournal">
      <journal-form @submit="submitJournal" @cancel="cancelJournal"></journal-form>
    </el-dialog>

  </div>
</template>

<script>
// import axios from 'axios'
// import BASE_URL from '@/utils/request'
import ConferenceForm from '@/components/ConferenceForm.vue';
import JournalForm from '@/components/JournalForm.vue';
import swal from "sweetalert";
export default {
  data() {
    return {
      showConferenceModal: false,
      showJournalModal: false
    };
  },
  methods: {
    showConferenceForm() {
      this.showConferenceModal = true;
    },
    showJournalForm() {
      this.showJournalModal = true;
    },
    handleCloseConference() {
      this.showConferenceModal = false;
      swal({
        title: "新增会议",
        text: "你取消了新增会议",
        icon: "error",
        button: "我知道了",
      });
    },
    handleCloseJournal() {
      this.showJournalModal = false;
      swal({
        title: "新增期刊",
        text: "你取消了新增期刊",
        icon: "error",
        button: "我知道了",
      });
    },
    submitConference(conferenceData) {
      console.log('提交会议数据:', conferenceData);
      // 此处可以调用接口发送请求保存会议数据
      // 示例：axios.post('/api/conferences', conferenceData)
      this.showConferenceModal = false;
    },
    cancelConference() {
      this.showConferenceModal = false;
    },
    submitJournal(journalData) {
      console.log('提交期刊数据:', journalData);
      // 此处可以调用接口发送请求保存期刊数据
      // 示例：axios.post('/api/journals', journalData)
      this.showJournalModal = false;
    },
    cancelJournal() {
      this.showJournalModal = false;
    }
  },
  components: {
    ConferenceForm,
    JournalForm
  }
};
</script>

<style scoped>
.box {
  border: 1px solid #dcdfe6; /* 外框颜色和宽度 */
  padding: 10px; /* 内边距，增加框内的空间 */
  border-radius: 4px; /* 圆角边框 */
}

.table-head-component {
  /* 使用内联样式或引入 CSS 变量 */
  background-color: #99ccff; /* 这里设置了背景颜色为红色 */
  /* 也可以使用 CSS 变量，比如 */
  /* background-color: var(--my-bg-color); */
  /* 然后在全局或局部样式中定义 --my-bg-color */

  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}
.conference-details {
  text-align: center; /* 居中对齐所有文本内容 */
}
</style>
