<!-- components/ConferenceForm.vue -->
<template>

  <div class="box">
    <div class="table-head-component">
      <i class="el-icon-edit"></i> 新增会议
    </div>
    <p></p>
    <el-form ref="form" :model="conferenceData" label-width="100px">
      <el-form-item label="全称">
        <el-input v-model="conferenceData.fullname"></el-input>
      </el-form-item>
      <el-form-item label="简称">
        <el-input v-model="conferenceData.shortname"></el-input>
      </el-form-item>

      <el-form-item label="截稿日期">
        <el-date-picker
            v-model="conferenceData.deadline"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="通知日期">
        <el-date-picker
            v-model="conferenceData.noticeDate"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-input v-model="conferenceData.status"></el-input>
      </el-form-item>

      <el-form-item label="会议日期">
        <el-date-picker
            v-model="conferenceData.meetingDate"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否推迟">
        <el-checkbox v-model="conferenceData.postponed"></el-checkbox>
      </el-form-item>

      <el-form-item label="会议地点">
        <el-input v-model="conferenceData.place"></el-input>
      </el-form-item>
      <el-form-item label="届数">
        <el-input v-model="conferenceData.sessionNumber"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" v-model="conferenceData.detail"></el-input>
      </el-form-item>
      <el-form-item label="网址">
        <el-input v-model="conferenceData.website"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancelSubmit">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import BASE_URL from "@/utils/request";
import swal from 'sweetalert'
export default {
  data() {
    return {
      conferenceData: {
        postponed:true
      },

    };
  },
  methods: {
    cancelSubmit() {
      this.$emit('cancel');
      swal({
        title: "新增会议",
        text: "你取消了新增会议",
        icon: "error",
        button: "我知道了",
      });
    },
    onSubmit() {
      this.$emit('submit', this.conferenceData);
      axios.post(BASE_URL+'/conference/add', {
        fullname:this.conferenceData.fullname,
        shortname:this.conferenceData.shortname,
        deadline:this.conferenceData.deadline,
        noticeDate:this.conferenceData.noticeDate,
        status:this.conferenceData.status,
        meetingDate:this.conferenceData.meetingDate,
        postponed:this.conferenceData.postponed,
        place:this.conferenceData.place,
        sessionNumber:this.conferenceData.sessionNumber,
        detail:this.conferenceData.detail,
        website:this.conferenceData.website,
        conference:this.conferenceData.conference
      }).then(res => {
        res.data
        // console.log(res.data.data.records);
        swal({
          title: "新增会议",
          text: "你成功新增了一条会议",
          icon: "success",
          button: "我知道了",
        });
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }
};
</script>

<style scoped>
/* 可以添加自定义样式 */
</style>
