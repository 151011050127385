<template>
  <div>
    <div>
      <el-descriptions class="margin-top" title="用户个人信息" :column="1" size="400" border style="width: 600px;">
        <template slot="extra">
          <el-button type="primary" size="small" @click="edit">修改个人信息</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            姓名
          </template>
          {{ userData.firstname }} {{ userData.lastname }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            全名（昵称）
          </template>
          {{ userData.fullname }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            邮箱
          </template>
          {{ userData.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            机构
          </template>
          {{ userData.institution }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            创建时间
          </template>
          {{ userData.createTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            活跃度
          </template>
          {{ userData.vitality }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            简介
          </template>
          {{ userData.resume }}
        </el-descriptions-item>
      </el-descriptions>
      <h1></h1>

      <!-- 如果有关注的会议和参与的会议信息，可以展示出来 -->
      <!--      关注的会议-->
      <div class="box">
        会议
        <div class="box">
          <div class="table-head-component">关注的会议</div>
          <el-table
              :data="userData.followConferences"
              border
              style="width: 100%"
              max-height="250">

            <el-table-column
                prop="ccf"
                label="CCF"
                width="50">
            </el-table-column>
            <el-table-column
                prop="core"
                label="CORE"
                width="60">
            </el-table-column>
            <el-table-column
                prop="qualis"
                label="QUALIS"
                width="70">
            </el-table-column>
            <el-table-column
                prop="shortname"
                label="简称"
                width="100">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="全称"
                width="400">
            </el-table-column>
            <el-table-column
                prop="deadline"
                label="截稿日期"
                width="70">
            </el-table-column>
            <el-table-column
                prop="noticeDate"
                label="通知日期"
                width="70">
            </el-table-column>
            <el-table-column
                prop="meetingDate"
                label="会议日期"
                width="70">
            </el-table-column>
            <el-table-column
                label="操作"
                width="50">
              <template>
                <el-button
                    @click.native.prevent="deleteRow('follow',conferenceId)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--        参加的会议-->
        <div class="box">
          <div class="table-head-component">参加的会议</div>
          <el-table
              :data="userData.attendConferences"
              border
              style="width: 100%"
              max-height="250">

            <el-table-column
                prop="ccf"
                label="CCF"
                width="50">
            </el-table-column>
            <el-table-column
                prop="core"
                label="CORE"
                width="60">
            </el-table-column>
            <el-table-column
                prop="qualis"
                label="QUALIS"
                width="70">
            </el-table-column>
            <el-table-column
                prop="shortname"
                label="简称"
                width="100">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="全称"
                width="400">
            </el-table-column>
            <el-table-column
                prop="deadline"
                label="截稿日期"
                width="70">
            </el-table-column>
            <el-table-column
                prop="noticeDate"
                label="通知日期"
                width="70">
            </el-table-column>
            <el-table-column
                prop="meetingDate"
                label="会议日期"
                width="70">
            </el-table-column>
            <el-table-column
                label="操作"
                width="50">
              <template>
                <el-button
                    @click.native.prevent="deleteRow('attend',conferenceId)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--        浏览的会议-->
        <div class="box">
          <div class="table-head-component">浏览的会议</div>
          <el-table
              :data="userData.browseConferences"
              border
              style="width: 100%"
              max-height="250">

            <el-table-column
                prop="ccf"
                label="CCF"
                width="50">
            </el-table-column>
            <el-table-column
                prop="core"
                label="CORE"
                width="60">
            </el-table-column>
            <el-table-column
                prop="qualis"
                label="QUALIS"
                width="70">
            </el-table-column>
            <el-table-column
                prop="shortname"
                label="简称"
                width="100">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="全称"
                width="400">
            </el-table-column>
            <el-table-column
                prop="deadline"
                label="截稿日期"
                width="70">
            </el-table-column>
            <el-table-column
                prop="noticeDate"
                label="通知日期"
                width="70">
            </el-table-column>
            <el-table-column
                prop="meetingDate"
                label="会议日期"
                width="70">
            </el-table-column>
            <el-table-column
                label="操作"
                width="50">
              <template>
                <el-button
                    @click.native.prevent="deleteRow('browse',conferenceId)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <h1></h1>
      <h1></h1>
      <div class="box">
        期刊
        <!--        关注的期刊-->
        <div class="box">
          <div class="table-head-component">关注的期刊</div>
          <el-table
              :data="userData.followJournals"
              border
              style="width: 100%">
            <el-table-column
                prop="ccf"
                label="CCF"
                width="50">
            </el-table-column>
            <el-table-column
                prop="shortname"
                label="简称"
                width="100">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="全称"
                width="300">
            </el-table-column>
            <el-table-column
                prop="impactFactors"
                label="影响因子"
                width="70">
            </el-table-column>
            <el-table-column
                prop="publisher"
                label="出版商"
                width="70">
            </el-table-column>
            <el-table-column
                prop="issn"
                label="ISSN"
                width="70">
            </el-table-column>
            <el-table-column
                label="操作"
                width="50">
              <template>
                <el-button
                    @click.native.prevent="deleteRow('follow',jounalId)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--        浏览的期刊-->
        <div class="box">
          <div class="table-head-component">浏览的期刊</div>
          <el-table
              :data="userData.browseJournals"
              border
              style="width: 100%">

            <el-table-column
                prop="ccf"
                label="CCF"
                width="50">
            </el-table-column>
            <el-table-column
                prop="fullname"
                label="全称"
                width="300">
            </el-table-column>
            <el-table-column
                prop="impactFactors"
                label="影响因子"
                width="70">
            </el-table-column>
            <el-table-column
                prop="publisher"
                label="出版商"
                width="70">
            </el-table-column>
            <el-table-column
                prop="issn"
                label="ISSN"
                width="70">
            </el-table-column>
            <el-table-column
                label="操作"
                width="50">
              <template>
                <el-button
                    @click.native.prevent="deleteRow('browse',jounalId)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <h1></h1>
      <h1></h1>
      <!--        关注的科研人员-->
      <div class="box">
        <div class="table-head-component">关注的科研人员</div>
        <el-table
            :data="userData.followUsers"
            border
            style="width: 100%">

          <el-table-column
              prop="ccf"
              label="姓名"
              width="100">
          </el-table-column>
          <el-table-column
              prop="core"
              label="	科研机构"
              width="200">
          </el-table-column>
          <el-table-column
              prop="qualis"
              label="注册时间"
              width="100">
          </el-table-column>
          <el-table-column
              prop="shortname"
              label="活跃度"
              width="100">
          </el-table-column>
          <el-table-column
              label="操作"
              width="50">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableData)"
                  type="text"
                  size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
// import axios from 'axios'
// import BASE_URL from '@/utils/request'
export default {
  name: 'AccountPage',
  computed: {
    ...mapState({
      userData: state => state.userData
    })
  },
  methods: {
    edit(){
      this.$router.push('/account/edit');
    },
    deleteRow(index, rows) {
      console.log(index+" "+rows)
    }
  },
}
</script>
<style>
.box {
  border: 1px solid #dcdfe6; /* 外框颜色和宽度 */
  padding: 10px; /* 内边距，增加框内的空间 */
  border-radius: 4px; /* 圆角边框 */
}

.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}
</style>