<template>
  <div class="login-container">
    <h2>用户登录</h2>
    <p>请填写如下登录信息:</p>
    <p>标记 * 为必填.</p>

    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-width="100px">
      <el-form-item label="Email *" prop="email">
        <el-input v-model="loginForm.email"></el-input>
      </el-form-item>
      <el-form-item label="Password *" prop="password">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>
      <div >
        <el-button type="primary" plain @click="register" >注册 </el-button>
        <el-button type="danger" plain @click="forgetPwd" >忘记密码？</el-button>
      </div>
      <div>
        <el-checkbox v-model="remembered">Remember me next time</el-checkbox>
      </div>

      <el-form-item>
        <el-button type="primary" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { mapState } from 'vuex';
import BASE_URL from '@/utils/request'

export default {
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      loginRules: {
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 2, max: 20, message: '密码长度在 2 到 20 个字符', trigger: 'blur'}
        ]
      },
      remembered:'1'
    };
  },
  computed: {
    ...mapState({
      userData: state => state.userData // 将 state 中的 userData 映射为组件的计算属性
    })
  },
  methods: {
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const formDataEncoded = qs.stringify(this.loginForm);
          axios.post(BASE_URL+'/user/login', formDataEncoded, {headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }})
              .then(response => {
                if (response.status == 200) {
                  // 登录成功处理，例如跳转到首页或其他页面
                  localStorage.setItem('token',response.data.data);
                  const token = localStorage.getItem('token');
                  axios.get(BASE_URL+'/user/detail', {
                    headers: {
                      'Authorization': token // 将token放在请求头部
                    }
                  }).then(response => {
                    this.$store.commit('setUserData', response.data.data); // 将用户信息保存到 Vuex
                    // 登录成功后跳转到首页或其他页面
                    if(this.userData.role==0){
                      this.$router.push('/manage');
                    }else{
                      this.$router.push('/account');
                    }
                  }).catch(error => {
                    this.error = '获取用户详情信息失败: ' + error.message;
                  });
                } else {
                  this.$message.error(response.data.message);
                }
              })
              .catch(error => {
                console.error('登录请求失败', error);
                this.$message.error('登录失败，请重试');
              });
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
    register(){
      this.$router.push('/register');
    },
    forgetPwd(){

    }
  }
};
</script>

<style scoped>
.login-container {
  width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
