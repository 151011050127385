<template>
  <div>
    <div class="box">
      <div class="table-head-component">
        <i class="el-icon-edit"></i> 编辑个人信息
      </div>
      <p></p>
      <el-form ref="form" :model="userData" label-width="100px">
        <el-form-item label="姓">
          <el-input v-model="userData.lastname "></el-input>
        </el-form-item>
        <el-form-item label="名">
          <el-input v-model="userData.firstname "></el-input>
        </el-form-item>
        <el-form-item label="全名">
          <el-input v-model="userData.fullname"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userData.email "></el-input>
        </el-form-item>
        <el-form-item label="机构">
          <el-input v-model="userData.institution "></el-input>
        </el-form-item>
        <el-form-item label="旧密码*">
          <el-input v-model="oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="newPassword1"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input v-model="newPassword2"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input type="textarea" v-model="userData.resume"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定修改</el-button>
          <el-button @click="cancelSubmit">取消修改</el-button>
        </el-form-item>
      </el-form>

    </div>

  </div>

</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios';
import BASE_URL from '@/utils/request'

export default {
  name: 'AccountEditPage',
  computed: {
    ...mapState({
      userData: state => state.userData
    })
  },
  methods: {
    async onSubmit() {
      if (this.newPassword1 !== this.newPassword2) {
        this.$message.error('两次输入的密码不一致');
        return;
      }else{
        this.userData.password = this.newPassword1;
        this.userData.oldPassword = this.oldPassword;
      }

      try {
        const token = localStorage.getItem('token'); // 替换为实际的token
        const response = await axios.post(BASE_URL + '/user/edit', this.userData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token // 设置请求头部
          }
        });

        if (response.data.success) {
          this.$message.success('信息更新成功');
        } else {
          console.log(response.data)
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        this.$message.error('信息更新失败');
      }
    },
    cancelSubmit() {
      this.$router.push('/account');
    },
  },
  data() {
    return {
      oldPassword: null,
      newPassword1: null,
      newPassword2: null,
    }
  }
}
</script>

<style scoped>
.box {
  border: 1px solid #dcdfe6; /* 外框颜色和宽度 */
  padding: 10px; /* 内边距，增加框内的空间 */
  border-radius: 4px; /* 圆角边框 */
}

.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}
</style>