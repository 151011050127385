<template>
  <div>
    <el-card class="box-card">
      <h2>{{ journal.fullname }}</h2>
      <p><strong>出版商:</strong> {{ journal.publisher }}</p>
      <p><strong>网站:</strong> {{ journal.website }}</p>
      <p><strong>issn:</strong> {{ journal.issn }}</p>
      <p><strong>影响因子:</strong> {{ journal.impactFactors }}</p>
      <p><strong>ccf:</strong> {{ journal.ccf }}</p>

      <p><strong>截稿日期:</strong> {{ journal.deadline }}</p>
      <p><strong>通知日期:</strong> {{ journal.noticeDate }}</p>
      <p><strong>通知日期:</strong> {{ journal.journalId }}</p>
      <p><strong>浏览:</strong> {{ journal.browse }}
      <strong>  关注:</strong> {{ journal.follow }}</p>
      <el-button
          @click.native.prevent="followConference()"
          size="small">
        关注
      </el-button>
      <el-divider></el-divider>
      <div>
        <h3>specialIssue</h3>
        <p>Topics of interest include,but are not limited to:</p>
        <p> {{ journal.specialIssue }}</p>
      </div>
      <el-divider></el-divider>
      <div>
        <h3>征稿主题</h3>
        <p>Topics of interest include,but are not limited to:</p>
        <p> {{ journal.detail }}</p>
      </div>
      <el-divider></el-divider>
    </el-card>
    <div class="box">
      <div class="table-head-component">相关会议</div>
      <el-table
          :data="tableRelatedConference"
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="core"
            label="CORE"
            width="60">
        </el-table-column>
        <el-table-column
            prop="qualis"
            label="QUALIS"
            width="70">
        </el-table-column>
        <el-table-column
            prop="shortname"
            label="简称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="noticeDate"
            label="通知日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="meetingDate"
            label="会议日期"
            width="70">
        </el-table-column>
        <el-table-column
            prop="place"
            label="会议地点"
            width="100">
        </el-table-column>
        <el-table-column
            prop="sessionNumber"
            label="届数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
      </el-table>
    </div>
    <div class="box">
      <div class="table-head-component">相关期刊</div>
      <el-table
          :data="tableRelatedJournal"
          border
          style="width: 100%">
        <el-table-column
            prop="ccf"
            label="CCF"
            width="50">
        </el-table-column>
        <el-table-column
            prop="fullname"
            label="全称"
            width="400">
        </el-table-column>
        <el-table-column
            prop="specialIssue"
            label="Special Issue"
            width="400">
        </el-table-column>
        <el-table-column
            prop="deadline"
            label="截稿日期"
            width="100">
        </el-table-column>

        <el-table-column
            prop="impactFactors"
            label="影响因子"
            width="100">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="出版商"
            width="100">
        </el-table-column>
        <el-table-column
            prop="actionCount"
            label="浏览"
            width="70">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      journal: this.$route.params.journal || {},
      tableRelatedConference:[],
      tableRelatedJournal:[],
    };
  },
  watch: {
    '$route.params.journal': function(newVal) {
      this.journal = newVal;
    }
  },
  mounted() {
    console.log(this.journal)
  }
};
</script>
<style scoped>
.table-head-component {
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  /* 其他样式 */
  padding: 10px;
  border: 1px solid #ccc;
}
</style>