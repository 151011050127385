import Vue from 'vue'
import App from '@/App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router/index.js"
import store from './store'; // 导入 Vuex store
import swal from 'sweetalert'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small' });

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ path: '/login' });
  } else {
    next();
  }
});

axios.interceptors.request.use(config => {
  // 开始进度条
  NProgress.start()
  // config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
axios.interceptors.response.use(config => {
  // 结束进度条
  NProgress.done()
  return config
})

new Vue({
  router,
  store, // 注册 Vuex store
  swal,
  render: h => h(App)
}).$mount('#app')
